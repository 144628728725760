import { useEffect } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { convertFStoDate } from '../utils/formatTime';
import {
  LeadFormSubmission,
  setLeadFormsSubmissions,
  setLeadFormsSubmissionsLoadingState,
} from 'src/redux/slices/leadFormsSubmissionSlice';
import { selectCurrentPlatformWorkspaceId } from '../redux/selectors/workspaces';

export default function useFormSubmissions() {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId) {
      dispatch(setLeadFormsSubmissionsLoadingState());
      const q = query(
        collection(DB, getCollection[Collection.LEADS_FORMS_SUBMISSIONS](workspaceId)),
        orderBy('createTime', 'desc'),
        limit(200)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const submissions = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            createTime: convertFStoDate(data.createTime),
          } as LeadFormSubmission;
        });

        dispatch(setLeadFormsSubmissions(submissions));
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
}
