import { useState } from 'react';
// @mui
import { Box, Grid, Card, Typography, Stack, Button } from '@mui/material';
// @types
import { CreditCard, UserAddressBook, UserInvoice } from '../../../../@types/user';
//
import useAuth from '../../../../hooks/useAuth';
import { Subscription } from '../../../../enum';
import ManageSubscription from '../../../../stripe/components/ManageSubscription';
import Subscribe from '../../../../stripe/components/Subscribe';
import useLocales from '../../../../hooks/useLocales';
import { PricingPlanCard } from '../../../pricing';
import { _pricingPlans } from '../../../../_mock';
import PricingPlanCardReview from '../../../pricing/PricingPlanCardReview';
import PricingPlanCardFlow from '../../../pricing/PricingPlanCardFlow';
import { useSelector } from '../../../../redux/store';
import { selectPlatform } from '../../../../redux/slices/userSlice';
import { Platforms } from '../../../../redux/slices/platform';
import PricingPlanCardPremiumLeads from '../../../pricing/PricingPlanCardLeads';
import {
  selectWorkspaceFlowHasWorskpaces,
  selectWorkspaceLeadsHasWorkspaces,
  selectWorkspaceLinksHasWorkspaces,
  selectWorkspaceReviewHasWorskpaces,
} from '../../../../redux/selectors/workspaces';
import SimplyReviewLogo from 'src/assets/Logo/SimplyReviewLogo';
import SimplyLeadsLogo from 'src/assets/Logo/SimplyLeadsLogo';
import SimplyFlowLogo from 'src/assets/Logo/SimplyFlowLogo';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';
import { FLOW, LABS, LEADS, LINKS, REVIEW } from 'src/theme/palette';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import SimplyFlowIcon from 'src/assets/Logo/SimplyFlowIcon';
import ProductButton from 'src/components/buttons/ProductButton';
import SimplyLinksIcon from 'src/assets/Logo/SimplyLinksIcon';
import PricingPlanCardBundle from 'src/sections/pricing/PricingPlanCardBundle';

// ----------------------------------------------------------------------

type Props = {
  cards: CreditCard[];
  invoices: UserInvoice[];
  addressBook: UserAddressBook[];
};

export default function AccountBilling({ cards, addressBook, invoices }: Props) {
  const platform = useSelector(selectPlatform);
  

  // selectWorkspaceLinksHasWorkspaces;
  // selectWorkspaceBookingsHasWorkspaces;
  // const extreme = _pricingPlans[2];
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <Card sx={{ p: 3 }}>
            <Typography
              variant="overline"
              sx={{ mb: 3, display: 'block', color: 'text.secondary' }}
            >
              {translate('billing.yourPlan')}
            </Typography>
            <Typography variant="h4">{capitalCase(user?.stripeRole)}</Typography>
            <Box
              sx={{
                mt: { xs: 2, sm: 0 },
                position: { sm: 'absolute' },
                top: { sm: 24 },
                right: { sm: 24 },
              }}
            >
              {/* <Button size="small" color="inherit" variant="outlined" sx={{ mr: 1 }}>
                Cancel plan
              </Button> 
              {user?.stripeRole === Subscription.FREE ? <Subscribe /> : <ManageSubscription />}
            </Box>
          </Card>

          {/* <AccountBillingPaymentMethod
            cards={cards}
            isOpen={open}
            onOpen={() => setOpen(!open)}
            onCancel={() => setOpen(false)}
          /> 

          {/* <AccountBillingAddressBook addressBook={addressBook} /> 
        </Stack>
      </Grid> */}

      {/* <Grid item xs={12} md={4}>
        <AccountBillingInvoiceHistory invoices={invoices} />
      </Grid> */}

      {/* {platform === Platforms.SIMPLY_REVIEW && ( */}
      <Grid item xs={12} md={4} key={'review.subscription'}>
        <PricingPlanCardReview index={1} isBillingPage />
      </Grid>
      {/* )} */}

      {/* {platform === Platforms.SIMPLY_FLOW && ( */}
      <Grid item xs={12} md={4} key={'flow.subscription'}>
        <PricingPlanCardFlow index={1} isBillingPage />
      </Grid>
      {/* )} */}
      {/* {platform === Platforms.SIMPLY_LEADS && ( */}
      <Grid item xs={12} md={4} key={'leads.subscription'}>
        <PricingPlanCardPremiumLeads index={1} isBillingPage />
      </Grid>
      <Grid item xs={12} md={12} key={'bundle'}>
        <PricingPlanCardBundle />
      </Grid>
      {/* )} */}
      {/* <Grid item xs={12} md={4} key={extreme.subscription}>
        <PricingPlanCard
          card={extreme}
          index={1}
          action={user?.stripeRole === Subscription.FREE ? <Subscribe /> : <ManageSubscription />}
        />
      </Grid> */}
    </Grid>
  );
}
