import { useTranslation } from 'react-i18next';
// @mui
// @ts-ignore
import { enUS, deDE, frFR } from '@mui/material/locale';
import flagDk from '../assets/flags/dk.svg';
import { setDoc, doc } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { Collection } from '../enum';
import { useSelector } from '../redux/store';
// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'Danish',
    value: 'da',
    systemValue: {},
    icon: flagDk,
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_en.svg',
  },
  // {
  //   label: 'German',
  //   value: 'de',
  //   systemValue: deDE,
  //   icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_de.svg',
  // },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: frFR,
  //   icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_fr.svg',
  // },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const userId = useSelector((state) => state.user.id);
  const intl = useSelector((state) => state.user?.intl);

  const langStorage = intl || localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = async (newlang: string) => {
    i18n.changeLanguage(newlang);
    if (userId) {
      await setDoc(
        doc(DB, getCollection[Collection.USERS](), userId),
        { intl: newlang },
        { merge: true }
      );
    }
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
