import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  // Google
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  signInWithCustomToken,
  updateProfile,
  connectAuthEmulator,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  DocumentData,
  query,
  where,
  getDocs,
  connectFirestoreEmulator,
} from 'firebase/firestore';
// import { getMessaging } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';
import { getDatabase, ref as realtimeRef, serverTimestamp, set } from 'firebase/database';
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/auth';
//
import { FIREBASE_API } from '../config';

import { Region, Subscription } from '../enum';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { Segment } from '../utils/segment';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from '../redux/store';
import { setUser, getInitialPlatform } from '../redux/slices/userSlice';
// import { setWorkspaceReviews, WorkspaceReview } from '../redux/slices/workspaceReviewsSlice';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['demo@minimals.cc'];

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);
export const DB = getFirestore(firebaseApp);
// export const messaging = getMessaging(firebaseApp);

export const realtimeDB = getDatabase(firebaseApp);

export const Functions = getFunctions(firebaseApp, Region.EU_WEST_3);
export const Storage = getStorage(firebaseApp);
if (process.env.NODE_ENV === 'development') {
  // connectFunctionsEmulator(Functions, 'localhost', 5001);
  // connectAuthEmulator(AUTH, 'http://localhost:9099');
  // connectFirestoreEmulator(DB, 'localhost', 8080);
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  Initial = 'INITIALISE',
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    formattedUser?: any;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

function identifyTidio({ id, email }: { id: string; email: string | null }) {
  if (email) {
    // @ts-ignore
    return window?.tidioChatApi?.setVisitorData({
      distinct_id: id,
      email,
    });
  }
  // @ts-ignore
  return window?.tidioChatApi?.setVisitorData({
    distinct_id: id,
  });
}

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const userId = useSelector((state) => state.user.id);
  const [state, dispatch] = useReducer(reducer, initialState);
  const appDispatch = useDispatch();
  const [profile, setProfile] = useState<DocumentData | undefined>();

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const hasPhone = user?.providerData.find((p) => p.providerId === 'phone');
          if (hasPhone) {
            signOut(AUTH);
            return;
          }
          const userRef = doc(DB, 'users', user.uid);
          // const workspaceRef = query(
          //   collection(DB, 'workspaceReviews'),
          //   where(`members.${user.uid}.uid`, `==`, user.uid)
          // );

          const docSnap = await getDoc(userRef);
          // const workspaces = await getDocs(workspaceRef);
          // const workspaceReviews = workspaces.docs.map((doc) => ({
          //   id: doc.id,
          //   ...doc.data(),
          // })) as WorkspaceReview[];
          // console.group('onAUTHSTATECHANGED');
          // console.log(docSnap.exists());
          if (docSnap.exists()) {
            // appDispatch(setUser({ ...user, stripeRole }));
            setProfile(docSnap.data());
            appDispatch(setUser({ ...user, id: docSnap.id, ...docSnap.data() }));
            // console.log({ profile: docSnap.data() });
            // appDispatch(
            //   setWorkspaceReviews({
            //     workspaces: workspaceReviews,
            //     current: docSnap.data()?.workspaceReviewsCurrent,
            //   })
            // );
          }
          // console.groupEnd();

          // const stripeRole = await getStripeRole();
          Segment.identify(user.uid, {});
          // console.log({ stripeRole });
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user: { ...user /* stripeRole */ } },
          });
          Sentry.setUser({ id: user.uid });
          // identifyTidio({ id: user.uid, email: user.email });

          // appDispatch(setUser({ ...user, stripeRole }));
          // window.analytics
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null },
          });
          Sentry.setUser(null);
        }
      }),
    [dispatch]
  );

  const login = async (email: string, password: string): Promise<void | string> => {
    try {
      await signInWithEmailAndPassword(AUTH, email, password);
    } catch (error) {
      const errorMessage = error.message;
      return errorMessage;
    }
  };
  const customToken = async (token: string): Promise<void | string> => {
    try {
      await signInWithCustomToken(AUTH, token);
    } catch (error) {
      const errorMessage = error.message;
      return errorMessage;
    }
  };

  const signInWithGoogle = async (event: 'sign_up' | 'sign_in' = 'sign_in') => {
    try {
      const result = await signInWithPopup(AUTH, new GoogleAuthProvider());

      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      const userRef = doc(collection(DB, 'users'), result.user.uid);
      await setDoc(
        userRef,
        {
          uid: result.user.uid,
          // settingUpWorkspace: true,
          platform: getInitialPlatform(),
        },
        { merge: true }
      );
      // @ts-ignore
      window?.dataLayer?.push({
        event,
        method: 'google',
        timestamp: new Date().getTime(),
        user_id: result.user.uid,
        user_type: event ? 'user' : 'trial_user',
      });

      // return result.user;
    } catch (error: any) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const { email } = error;
      // The AuthCredential type that was used.
      console.error(error);

      const credential = GoogleAuthProvider.credentialFromError(error);
      return errorMessage;
      // ...
    }
  };
  const signInWithFacebook = async () => {
    try {
      const provider = new FacebookAuthProvider();
      provider.addScope('public_profile');
      const result = await signInWithPopup(AUTH, provider);

      // The signed-in user info.

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential?.accessToken;

      // return result.user;

      // ...
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const { email } = error;
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error);
      console.error({ errorCode, errorMessage });
      return errorMessage;

      // ...
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    try {
      const res = await createUserWithEmailAndPassword(AUTH, email, password);
      updateProfile(res.user, {
        displayName: `${firstName} ${lastName}`,
      });
      const userRef = doc(collection(DB, 'users'), res.user?.uid);
      await setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
        // settingUpWorkspace: true,
        platform: getInitialPlatform(),
      });
      // @ts-ignore
      window?.dataLayer?.push({
        event: 'sign_up',
        login_status: 'logged_in',
        method: 'email',
        timestamp: new Date().getTime(),
        user_id: res.user.uid,
        user_type: 'trial_user',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const resetPassword = async (email: string): Promise<string | void> => {
    try {
      await sendPasswordResetEmail(AUTH, email);
      // Password reset email sent!
      // ..
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      return error.message;
      // ..
    }
  };

  const logout = async () => {
    await set(realtimeRef(realtimeDB, `users/${userId}`), {
      state: 'offline',
      offline_at: serverTimestamp(),
    });
    signOut(AUTH);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: state?.user?.displayName || profile?.displayName,
          role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : 'user',
          phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
          stripeRole: state?.user?.stripeRole,
        },
        customToken,
        login,
        register,
        logout,
        signInWithGoogle,
        signInWithFacebook,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

export const getStripeRole = async (): Promise<Subscription> => {
  await AUTH.currentUser?.getIdToken(true);
  const decodedToken = await AUTH.currentUser?.getIdTokenResult();

  return (decodedToken?.claims.stripeRole as Subscription) || Subscription.FREE;
};

export const formatUser = async (user: any) => ({
  uid: user.uid,
  email: user.email,
  name: user.displayName,
  token: user.xa,
  provider: user.providerData[0].providerId,
  photoUrl: user.photoURL,
  stripeRole: null, // await getStripeRole(),
});
