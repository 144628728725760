import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Grid,
  Card,
  Stack,
  Typography,
  InputAdornment,
  useTheme,
  Switch,
  Tooltip,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
// utils
import { fData } from '../../../../utils/formatNumber';
// _mock
// import { countries } from '../../../../_mock';
import useLocales from '../../../../hooks/useLocales';
// components
import {
  FormProvider,
  RHFTextField,
  RHFUploadButton,
  RHFUploadPageAvatar,
} from '../../../../components/hook-form';
import { Collection, Intl, ROOTS } from '../../../../enum';
import DraggableList from '../../../../components/DraggableList';
import ColorPicker from '../../../../components/colorPicker/index';
import Iconify from '../../../../components/Iconify';
import Android12Switch from '../../../../components/customSwitch/index';
import { Button, Divider, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PremiumFeatureLabel } from '../../../../components/PremiumFeature';
import InfoTooltip from '../../../../components/InfoTooltip';
import { getCollection } from '../../../../api';
import { setDoc, doc } from 'firebase/firestore';
import { DB } from '../../../../contexts/FirebaseContext';
import { useSelector } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import useResponsiveQuery from '../../../../hooks/useResponsiveQuery';
// ----------------------------------------------------------------------
import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { countries, CountryField } from '../../../../components/PhoneField';
import { hasPremiumSelector } from '../../../../redux/slices/workspaceReviewsSlice';

enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
}

type FormValuesProps = {
  slug: string;
  logo: File;
  title: string;
  description: string;
  videoIntro: any;
  companyName: string;
  reviewerPageLanguage: Intl;
};

interface Props {
  configId?: string;
  // slug: any;
  // onChangeSlug: any;
  // onChangeCompanyName: any;
  onSubmitSlug: (data: FormValuesProps) => Promise<void>;
  setavatar: any;
  setVideoIntro: any;
  initialQuestions: any;
  onQuestionChange: any;
  // title: any;
  onTitleChange: any;
  // description: any;
  onDescriptionChange: any;
  color: any;
  onColorChange: any;
  onlyVideo: any;
  onOnlyVideoChange: any;
  logoInit: any;
  videoIntroInit: any;
  onReviewerPageLanguageChange: any;
  defaultValues: any;
}

export default function OnboardingSlug({
  configId,
  // slug,
  // onChangeSlug,
  // onChangeCompanyName,
  onSubmitSlug,
  setavatar,
  setVideoIntro,
  initialQuestions,
  onQuestionChange,
  // title,
  onTitleChange,
  // description,
  onDescriptionChange,
  color,
  onColorChange,
  onlyVideo,
  onOnlyVideoChange,
  logoInit,
  videoIntroInit,
  onReviewerPageLanguageChange,
  defaultValues,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useResponsiveQuery();
  const UpdateUserSchema = Yup.object().shape({
    logo: Yup.mixed(),
    videoIntro: Yup.mixed(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    // reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await onSubmitSlug(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: any[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'logo',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setavatar(URL.createObjectURL(file));
      }
    },
    [setValue]
  );
  const handleDropVideo = useCallback(
    (acceptedFiles: any[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue('videoIntro', null);
        setValue(
          'videoIntro',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setVideoIntro(URL.createObjectURL(file));
      }
    },
    [setValue]
  );

  const handleDeleteLogo = async () => {
    try {
      if (configId) {
        await setDoc(
          doc(DB, getCollection[Collection.PUBLIC](), configId),
          { logo: null },
          { merge: true }
        );
        // @ts-ignore
        setValue('logo', undefined);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteVideo = async () => {
    try {
      if (configId) {
        await setDoc(
          doc(DB, getCollection[Collection.PUBLIC](), configId),
          { videoIntro: null },
          { merge: true }
        );
        // @ts-ignore
        setValue('videoIntro', undefined);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const hasPremium = useSelector(hasPremiumSelector);

  const rootUrl = process.env.NODE_ENV === 'development' ? ROOTS.DEV : ROOTS.PROD;
  const [option, setOption] = React.useState(MediaType.VIDEO);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Stack direction="row" justifyContent="space-between">
            <CountryField
              size="small"
              variant="outlined"
              label={t('reviewPageSettings.form.template.langLabel')}
              placeholder={t('reviewPageSettings.form.template.langPlaceholder') as string}
              id="outlined-select-currency"
              name="reviewerPageLanguage"
              onChange={(e: any) => {
                onReviewerPageLanguageChange(e.target.value);
                setValue('reviewerPageLanguage', e.target.value);
              }}
            >
              {countries
                .filter((c) => c.code === 'DK' || c.code === 'EN' || c.code === 'NO')
                .map((option) => (
                  <option key={option.languageCode} value={option.languageCode}>
                    {option.flag} {option.language}
                  </option>
                ))}
            </CountryField>
            {/* </Box> */}
          </Stack>
          {/* <Typography variant="body2" color={theme.palette.grey[400]}>
              Before integrating review modules on your website, you’ll need to create chose a URL.
              This URL is where future customers can locate all your reviews.
            </Typography> */}
          <Box sx={{ mt: 3 }} />

          <Box>
            <Grid container spacing={2}>
              {MediaType.IMAGE === option && (
                <Grid md={12}>
                  <Stack>
                    <RHFUploadPageAvatar
                      name="logo"
                      accept={{ 'image/*': [] }}
                      maxSize={3145728}
                      onDrop={handleDrop}
                    />
                    {logoInit && (
                      <Tooltip title={t('reviewPageSettings.logo.remove.tooltip') as string}>
                        <Button
                          startIcon={<Iconify icon="ant-design:delete-outlined" />}
                          onClick={handleDeleteLogo}
                        >
                          {t('reviewPageSettings.logo.remove.button')}
                        </Button>
                      </Tooltip>
                    )}
                    <Box sx={{ mb: 2 }} />
                  </Stack>
                </Grid>
              )}

              {MediaType.VIDEO === option && (
                <Grid md={12}>
                  <Stack direction="row" alignContent="center" justifyContent="center">
                    <Stack>
                      <Box sx={{ mt: 2 }} />

                      <RHFUploadButton
                        name="videoIntro"
                        accept={{ 'video/*': [] }}
                        disabled={!hasPremium}
                        text={
                          values.videoIntro
                            ? t('reviewPageSettings.video.swap')
                            : t('reviewPageSettings.video.upload')
                        }
                        // maxSize={3145728}
                        onDrop={handleDropVideo}
                      />
                      {videoIntroInit && (
                        <Tooltip title={t('reviewPageSettings.video.remove.tooltip') as string}>
                          <Button
                            disabled={!hasPremium}
                            startIcon={<Iconify icon="ant-design:delete-outlined" />}
                            onClick={handleDeleteVideo}
                          >
                            {t('reviewPageSettings.video.remove.button')}
                          </Button>
                        </Tooltip>
                      )}
                      <Box sx={{ mb: 2 }} />
                    </Stack>
                  </Stack>
                  {/* <label htmlFor="contained-button-file">
                  <Input accept="video/*" id="contained-button-file" multiple type="file" />
                  <Button variant="contained" component="span">
                    Upload video
                  </Button>
                </label> */}
                  {/* <Button>Upload video</Button> */}
                </Grid>
              )}
              <Grid md={12}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <ToggleButtonGroup
                    color="primary"
                    value={option}
                    exclusive
                    size="small"
                    onChange={(_, newOption) => newOption && setOption(newOption)}
                    aria-label="Platform"
                  >
                    <ToggleButton value="image">Image</ToggleButton>
                    <ToggleButton value="video" disabled={!hasPremium}>
                      Video
                      <Box sx={{ ml: 0.5 }}>
                        <PremiumFeatureLabel />
                      </Box>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box>
            <Typography variant="subtitle1">{t('reviewPageSettings.title.header')}</Typography>
            <Box sx={{ mt: 1 }} />
          </Box>
          <Box>
            <RHFTextField
              name="title"
              size="small"
              // value={title}
              // label={t('reviewPageSettings.title.label')} // t('form.companyslug.placeholder')}
              onChange={(e: any) => {
                onTitleChange(e.target.value);
                setValue('title', e.target.value);
              }}
            />
          </Box>
          {/* <TextField
          label="With normal TextField"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          
        /> */}
        </Grid>
        <Grid item xs={12} md={12}>
          <Box>
            <Typography variant="subtitle1">
              {t('reviewPageSettings.description.header')}
            </Typography>
            <Box sx={{ mt: 1 }} />
          </Box>
          <Box>
            <RHFTextField
              name="description"
              // value={description}
              // label={t('reviewPageSettings.description.label')} // t('form.companyslug.placeholder')}
              onChange={(e: any) => {
                onDescriptionChange(e.target.value);
                setValue('description', e.target.value);
              }}
              multiline
              minRows={3}
              maxRows={10}
            />
          </Box>
          {/* <TextField
          label="With normal TextField"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          
        /> */}
        </Grid>
        <Grid item xs={12} md={12}>
          <Box>
            <Typography variant="subtitle1">{t('reviewPageSettings.questions.header')}</Typography>
            {/* <Typography variant="body2" color={theme.palette.grey[400]}>
              Before integrating review modules on your website, you’ll need to create chose a URL.
              This URL is where future customers can locate all your reviews.
            </Typography> */}
            <Box sx={{ mt: 2 }} />
          </Box>
          <Box>
            <DraggableList initialItems={initialQuestions} onChange={onQuestionChange} />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1">{t('reviewPageSettings.brandColor.header')}</Typography>
            <PremiumFeatureLabel />
          </Stack>
          <Box sx={{ mt: 1 }}>
            <ColorPicker disabled={!hasPremium} color={color} onChange={onColorChange} />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1">{t('reviewPageSettings.onlyVideo.header')}</Typography>
            <PremiumFeatureLabel />
          </Stack>
          <Box sx={{ mt: 0 }}>
            <Android12Switch
              disabled={!hasPremium}
              checked={onlyVideo}
              onChange={(e: any) => onOnlyVideoChange(e.target.checked)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Stack
            spacing={3}
            alignItems="flex-end"
            sx={{
              pt: 3,
              pb: 3,
              pr: 2,
              position: 'fixed',
              bottom: 0,
              background:
                'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9004595588235294) 50%, rgba(255,255,255,0) 100%)',
              // backgroundColor: theme.palette.background.paper,
              width: isMobile ? 'calc(100%)' : 'calc(33%)',
              // zIndex: 1000,
              left: 0,
            }}
          >
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {t('general.form.saveChanges')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
