import React from 'react';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Iconify from '../../components/Iconify';
import Button from '@mui/material/Button';
import Instagram from './Instagram';
// import VideoEditor from '../../components/editor/video';

const instagramAppId = '755068999202274';

export default function Social() {
  const responseFacebook = (response: any) => {
    if (response.accessToken) {
      // setData(response);
      // setPicture(response.picture.data.url);
      // onFBVerify(response);
      // setLogin(true);
    } else {
      // setLogin(false);
    }
  };

  const onInstagram = async () => {
    try {
      const response = await fetch(
        `https://api.instagram.com/oauth/authorize?client_id=${instagramAppId}&redirect_uri=https://localhost:3000/&scope=user_profile,user_media&response_type=code`
      );
      const data = await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <FacebookLogin
        appId="914595409221110"
        autoLoad={false}
        fields="name,email,picture"
        scope="public_profile"
        callback={responseFacebook}
        disableMobileRedirect
        render={(renderProps: any) => (
          <Button
            fullWidth
            variant="contained"
            startIcon={<Iconify icon={'eva:facebook-fill'} color="#fff" width={32} height={32} />}
            onClick={renderProps.onClick}
          >
            Connect
          </Button>
        )}
      />
      <Button onClick={onInstagram}>Instagram</Button>
      <Instagram />
      {/* <VideoEditor /> */}
    </div>
  );
}
