import React, { useEffect } from 'react';
import {
  Alert,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  AlertTitle,
  Tooltip,
} from '@mui/material';
import Iconify from '../../components/Iconify';
import InviteForm from './InviteForm';
import Page from '../../components/Page';
import { useTheme } from '@mui/material/styles';
import { useSelector } from '../../redux/store';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { PremiumFeatureLabel } from '../../components/PremiumFeature';
import { countries, CountryField } from '../../components/PhoneField';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { NewInvites } from '../../@types/blog';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from '../../components/hook-form';
import { writeBatch, doc, collection } from 'firebase/firestore';
import { DB } from '../../contexts/FirebaseContext';
import { getCollection } from '../../api';
import { Collection, Intl, InviteReminderStatus, LanguageCode } from '../../enum';
import { useSearchParams, useNavigate } from 'react-router-dom';
import EmailPlaceholder from './EmailPlaceholder';
import SmsPlaceholder from './SmsPlaceholder';
import { InfoIcon } from '../../theme/overrides/CustomIcons';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import { getLngCodeFromLocal } from '../../locales/helper';
import { selectHasCurrentPlatformPremium } from '../../redux/selectors/workspaces';
import { AutomationType } from 'src/redux/slices/automationsSlice';
import { getFirstName } from 'src/utils/names';
import { loadinInviteReminders } from 'src/redux/slices/inviteReminders';

// @ts-ignore
const emailAndSmsSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object().shape({
    language: Yup.string().required(t('required lang')),
    invites: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('invites.validation.name')),
        email: Yup.string()
          .email(t('invites.validation.email'))
          .required(t('invites.validation.email')),
        countryCode: Yup.string().required(t('invites.validation.countryCode')),
        phone: Yup.string()
          .transform((currentValue) => currentValue.split(' ').join(''))
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            t('invites.validation.phone')
          ),
      })
    ),
  });
// @ts-ignore
const emailOnlySchema = (t: TFunction<'translation', undefined>) =>
  Yup.object().shape({
    language: Yup.string().required(t('required lang')),
    invites: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('invites.validation.name')),
        email: Yup.string()
          .email(t('invites.validation.email'))
          .required(t('invites.validation.email')),
      })
    ),
  });

enum TemplateType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}
export type inviteType = 'email_sms' | 'email' | 'manual';

function resolveInviteType(type: inviteType) {
  switch (type) {
    case 'email_sms':
      return { sendEmail: true, sendSms: true };
    case 'email':
      return { sendEmail: true, sendSms: false };
    case 'manual':
      return { sendEmail: false, sendSms: false };
    default:
      throw new Error('Not implemented');
  }
}
const defaultValues = {
  language: LanguageCode.EN,
  invites: [
    {
      name: '',
      email: '',
      countryCode: '+45',
      phone: '',
    },
    {
      name: '',
      email: '',
      countryCode: '+45',
      phone: '',
    },
  ],
};
export default function InviteAdd() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const includeSms = searchParams.get('includeSms');
  const isManual = searchParams.get('manual');
  const zenfit = searchParams.get('zenfit');
  const { defaultInvites, totalInviteCount } = useSelector((state) => ({
    defaultInvites:
      zenfit === AutomationType.REVIEW_REMINDER
        ? state.inviteReminders.list

            .filter((c) => (includeSms ? c.phone : c))
            .map((c) => ({
              id: c.clientId,
              email: c.email,
              name: getFirstName(c.name),
              countryCode: c.countryCode,
              phone: `${c.phone}`,
            }))
        : [],
    totalInviteCount: state.inviteReminders.list.length,
  }));

  const loadingInviteReminders = useSelector(loadinInviteReminders);
  const workspaceId = useSelector((state) => state.workspaceReview.currentWorkspace?.id);
  const smsLimit = useSelector((state) => state.workspaceReview.currentWorkspace?.smsLimit || 0);
  const hasPremium = useSelector(selectHasCurrentPlatformPremium);
  const userId = useSelector((state) => state.user.id);
  const intl = useSelector((state) => state.user.intl);
  const companyName = useSelector((state) => state.publicConfig.companyName);
  const [option, setOption] = React.useState(TemplateType.EMAIL);

  const InviteSchema = includeSms ? emailAndSmsSchema(t) : emailOnlySchema(t);
  const methods = useForm<NewInvites>({
    resolver: yupResolver(InviteSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'invites', // unique name for your Field Array
  });

  useEffect(() => {
    if (defaultInvites.length) {
      setValue('invites', defaultInvites);
      if (defaultInvites.length < totalInviteCount && includeSms) {
        enqueueSnackbar(t('invites.add.filteredNoPhoneInvites'), { variant: 'info' });
      }
    }
  }, [loadingInviteReminders]);

  useEffect(() => {
    setValue('language', getLngCodeFromLocal(intl) as string);

    return () => {};
  }, [intl]);

  const goBack = () => navigate(-1);

  const onSubmit = async (data: NewInvites) => {
    try {
      const type: inviteType = isManual ? 'manual' : includeSms ? 'email_sms' : 'email';
      // await new Promise((resolve) => setTimeout(resolve, 500));
      const inviteType = resolveInviteType(type);
      if (!workspaceId || !inviteType) return alert(t('general.error.default'));
      if (includeSms) {
        const hasExceeded = smsLimit - values.invites.length < 0;
        if (hasExceeded) {
          enqueueSnackbar(t('invites.add.smsLimitExceeded'), { variant: 'error' });
          return;
        }
      }
      const batch = writeBatch(DB);

      data.invites.forEach((invite) => {
        const payload = {
          email: invite.email,
          name: invite.name,
          countryCode: invite.countryCode,
          phone: invite.phone,
          createTime: new Date(),
          status: 'PENDING',
          sendSms: inviteType.sendSms,
          sendEmail: inviteType.sendEmail,
          language: data.language,
          invitedBy: userId,
          ...(zenfit && { clientId: invite.id, inviteType: AutomationType.REVIEW_REMINDER }),
        };

        batch.set(
          doc(collection(DB, getCollection[Collection.USERS_INVITES](workspaceId))),
          payload,
          { merge: true }
        );
      });
      if (includeSms) {
        batch.set(
          doc(collection(DB, getCollection[Collection.WORKSPACE_REVIEWS]()), workspaceId),
          {
            smsLimit: smsLimit - values.invites.length,
          },
          { merge: true }
        );
      }

      if (zenfit === AutomationType.REVIEW_REMINDER) {
        data.invites
          .filter((invite) => invite.id)
          .forEach((invite) => {
            batch.set(
              doc(
                collection(DB, getCollection[Collection.USERS_INVITES_REMINDERS](workspaceId)),
                `zenfit_client_${invite.id}`
              ),
              {
                status: InviteReminderStatus.PROCESSING,
              },
              { merge: true }
            );
          });
      }
      await batch.commit();
      if (zenfit === AutomationType.REVIEW_REMINDER) {
        enqueueSnackbar(t('invites.invitesSentZenfit'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('invites.invitesSent'), { variant: 'success' });
      }
      goBack();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page title="Send invites" sx={{ height: '100%' }}>
      <AppBar sx={{ position: 'relative' }} elevation={1} color="transparent">
        <Toolbar>
          <Button
            startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />}
            variant="text"
            onClick={goBack}
          >
            {t('adminbanner.backToDashboard')}
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          {includeSms && !hasPremium ? (
            <Tooltip title={t('invites.add.smslimit.trial.tooltip')}>
              <Typography variant="body2">
                {t('invites.add.smslimit.trial.text', { smsLimit })}
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip title={t('invites.add.smslimit.premium.tooltip')}>
              <Typography>{t('invites.add.smslimit.premium.text', { smsLimit })}</Typography>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ mt: 2 }} />
      <FormProvider
        style={{ height: 'calc(100% - 64px)' }}
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          spacing={2}
          sx={{ height: '100%' }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} sx={{ height: '100%' }}>
            <Box sx={{ p: 4, pt: 4, position: 'relative', height: '100%' }}>
              <Typography variant="h3" fontWeight={400}>
                {includeSms ? t('invites.form.headerSMS') : t('invites.form.header')}
              </Typography>
              <Box sx={{ mt: 2 }} />
              <Box sx={{ py: 2, maxHeight: '80%', overflowY: 'scroll' }}>
                <InviteForm
                  remove={remove}
                  append={append}
                  errors={errors}
                  fields={fields}
                  isSubmitting={isSubmitting}
                  includeSms={includeSms}
                  submit={handleSubmit(onSubmit)}
                />
              </Box>

              <Box sx={{ position: 'absolute', bottom: 0, p: 4, ml: -4, width: '100%' }}>
                <Slide direction="right" in mountOnEnter unmountOnExit>
                  <Alert
                    severity="info"
                    icon={<InfoIcon sx={{ color: theme.palette.primary.darker }} />}
                    sx={{
                      justifySelf: 'center',
                      bottom: 0,
                      backgroundColor: theme.palette.primary.lighter,
                    }}
                  >
                    <AlertTitle sx={{ fontSize: 14 }} color={theme.palette.primary.darker}>
                      {t('invites.form.tip.headline')}
                    </AlertTitle>
                    <Typography fontSize={14} color={theme.palette.primary.darker}>
                      {t('invites.form.tip.body')}
                    </Typography>
                  </Alert>
                </Slide>
              </Box>
            </Box>
          </Grid>
          {!isManual && (
            <Grid item xs={6} sx={{ backgroundColor: theme.palette.grey[100], height: '100%' }}>
              <Box sx={{ px: 8, flex: 1, height: '100%', overflow: 'hidden' }}>
                <Stack sx={{ py: 4 }} direction="row" justifyContent="space-between">
                  <Box>
                    <ToggleButtonGroup
                      color="primary"
                      value={option}
                      exclusive
                      // size="small"
                      onChange={(_, newOption) => newOption && setOption(newOption)}
                      aria-label="Platform"
                    >
                      <ToggleButton sx={{ px: 4 }} value={TemplateType.EMAIL}>
                        Email
                      </ToggleButton>
                      <ToggleButton sx={{ px: 4 }} value={TemplateType.SMS} disabled={!includeSms}>
                        SMS
                        <PremiumFeatureLabel />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <Box>
                    <CountryField
                      variant="outlined"
                      label={t('invites.form.template.langLabel')}
                      placeholder={t('invites.form.template.langPlaceholder') as string}
                      id="outlined-select-currency"
                      name="language"
                    >
                      {countries
                        .filter((c) => c.code === 'DK' || c.code === 'EN' || c.code === 'NO')
                        .map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.flag} {option.language}
                          </option>
                        ))}
                    </CountryField>
                  </Box>
                </Stack>
                <Slide direction="up" in={option === TemplateType.SMS} mountOnEnter unmountOnExit>
                  <Box>
                    <SmsPlaceholder
                      companyName={companyName}
                      language={values.language as LanguageCode}
                    />
                  </Box>
                </Slide>
                <Slide direction="up" in={option === TemplateType.EMAIL} mountOnEnter unmountOnExit>
                  <Box>
                    <EmailPlaceholder
                      companyName={companyName}
                      language={values.language as LanguageCode}
                    />
                  </Box>
                </Slide>
              </Box>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </Page>
  );
}
