import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InviteStatus, RequestState } from '../../enum';
import type { RootState } from '../store';

export interface InviteReminder {
  id: string;
  name: string;
  clientId: string;
  email: string;
  countryCode: string;
  phone: number;
  status: InviteStatus;
  endDate: Date;
  photo: string | null;
}

// Define a type for the slice state
interface InviteState {
  list: InviteReminder[];
  loading: RequestState;
}

// Define the initial state using that type
const initialState: InviteState = {
  list: [],
  loading: RequestState.IDLE,
};

export const inviteRemindersSlice = createSlice({
  name: 'inviteReminders',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setInviteRemindersLoadingState: (state) => ({
      ...state,
      loading: RequestState.PENDING,
    }),
    setInviteReminders: (state, action: PayloadAction<InviteReminder[]>) => ({
      ...state,
      list: action.payload,
      loading: RequestState.RESOLVED,
    }),
  },
});

export const { setInviteReminders, setInviteRemindersLoadingState } = inviteRemindersSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectInviteReminders = (state: RootState) => state.inviteReminders.list;

export const selectInviteRemindersCount = (state: RootState) => state.inviteReminders.list.length;

export const loadinInviteReminders = (state: RootState) =>
  state.inviteReminders.loading === RequestState.IDLE ||
  state.inviteReminders.loading === RequestState.PENDING;

export default inviteRemindersSlice.reducer;
