import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

export default function Instagram() {
  const [imageUrl, setImageUrl] = useState('');
  const [postCaption, setPostCaption] = useState('');
  const [isSharingPost, setIsSharingPost] = useState(false);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState('');

  /* --------------------------------------------------------
   *                      FACEBOOK LOGIN
   * --------------------------------------------------------
   */

  // Check if the user is authenticated with Facebook
  useEffect(() => {
    window.FB.getLoginStatus((response: any) => {
      setFacebookUserAccessToken(response.authResponse?.accessToken);
    });
  }, []);

  const logInToFB = () => {
    window.FB.login(
      (response: any) => {
        setFacebookUserAccessToken(response.authResponse?.accessToken);
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope: 'instagram_basic,pages_show_list,instagram_content_publish',
      }
    );
  };

  const logOutOfFB = () => {
    window.FB.logout(() => {
      setFacebookUserAccessToken('');
    });
  };

  /* --------------------------------------------------------
   *             INSTAGRAM AND FACEBOOK GRAPH APIs
   * --------------------------------------------------------
   */

  const getFacebookPages = (): Promise<any[]> =>
    new Promise((resolve) => {
      window.FB.api('me/accounts', { access_token: facebookUserAccessToken }, (response: any) => {
        resolve(response.data);
      });
    });

  const getInstagramAccountId = (facebookPageId: string): Promise<any> =>
    new Promise((resolve) => {
      window.FB.api(
        facebookPageId,
        {
          access_token: facebookUserAccessToken,
          fields: 'instagram_business_account',
        },
        (response: any) => {
          resolve(response.instagram_business_account.id);
        }
      );
    });

  const createMediaObjectContainer = (instagramAccountId: string): Promise<any> =>
    new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        'POST',
        {
          access_token: facebookUserAccessToken,
          video_url: imageUrl,
          caption: postCaption,
          media_type: 'VIDEO',
        },
        (response: any) => {
          resolve(response.id);
        }
      );
    });

  const publishMediaObjectContainer = (
    instagramAccountId: string,
    mediaObjectContainerId: string
  ) =>
    new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        'POST',
        {
          access_token: facebookUserAccessToken,
          creation_id: mediaObjectContainerId,
        },
        (response: any) => {
          resolve(response.id);
        }
      );
    });

  // TODO: wait for post to get published
  const shareInstagramPost = async () => {
    setIsSharingPost(true);
    const facebookPages = await getFacebookPages();
    const instagramAccountId = await getInstagramAccountId(facebookPages[0].id);
    const mediaObjectContainerId = await createMediaObjectContainer(instagramAccountId);

    setTimeout(async () => {
      await publishMediaObjectContainer(instagramAccountId, mediaObjectContainerId);

      setIsSharingPost(false);

      // Reset the form state
      setImageUrl('');
      setPostCaption('');
    }, 20000);
  };

  return (
    <main id="app-main">
      <section className="app-section">
        <h3>1. Log in with Facebook</h3>
        {facebookUserAccessToken ? (
          <Button onClick={logOutOfFB} className="btn action-btn">
            Log out of Facebook
          </Button>
        ) : (
          <Button onClick={logInToFB} className="btn action-btn">
            Login with Facebook
          </Button>
        )}
      </section>
      {facebookUserAccessToken ? (
        <section className="app-section">
          <h3>2. Send a post to Instagram</h3>
          <input
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            placeholder="Enter a JPEG image url..."
          />
          <textarea
            value={postCaption}
            onChange={(e) => setPostCaption(e.target.value)}
            placeholder="Write a caption..."
          />
          <Button
            onClick={shareInstagramPost}
            className="btn action-btn"
            disabled={isSharingPost || !imageUrl}
          >
            {isSharingPost ? 'Sharing...' : 'Share'}
          </Button>
        </section>
      ) : null}
    </main>
  );
}
