import useAuth from '../../hooks/useAuth';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import useUsers from '../../hooks/useUsers';
import { useSelector } from '../../redux/store';
import {
  TextField,
  Stack,
  Card,
  Tooltip,
  IconButton,
  Typography,
  Box,
  Button,
} from '@mui/material';
import Page from '../../components/Page';
import { useTheme } from '@mui/material/styles';
import UserDrawer from './Drawer';
import Iconify from '../../components/Iconify';
import useUserPresence from '../../hooks/useUserPresence';
import { formatDistanceToNow } from 'date-fns';
import { PATH_DASHBOARD } from '../../routes/paths';
import useUnverifiedReviews from './useUnverifiedReviews';
import UnverifiedReviewsList from './UnverifiedReviewsList';

export default function CustomToken() {
  useUsers();
  useUnverifiedReviews();
  const { userPresenceMap } = useUserPresence();
  const { t } = useTranslation();
  const theme = useTheme();
  const { customToken } = useAuth();
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const users = useSelector((state) => state.users.users);
  const [openDrawer, setOpenDrawer] = useState<null | string>(null);

  const handleCloseDrawer = () => setOpenDrawer(null);

  useEffect(() => {
    const param = searchParams.get('token');
    if (param) {
      const signin = async () => {
        await customToken(param);
        navigate('/');
      };
      signin();
    }
  }, []);

  const filteredUsers = users
    .filter((user) => {
      if (user.displayName?.toLowerCase().includes(searchText?.toLowerCase())) return true;
      if (user.email?.toLowerCase().includes(searchText?.toLowerCase())) return true;
      if (user.id?.toLowerCase().includes(searchText?.toLowerCase())) return true;
      return false;
    })
    .sort((a, b) => {
      if (a.displayName?.toLowerCase() < b.displayName?.toLowerCase()) return -1;
      if (a.displayName?.toLowerCase() > b.displayName?.toLowerCase()) return 1;
      return 0;
    });

  return (
    <Page title="Users">
      <Stack direction="row" sx={{ p: 2 }}>
        <Button
          startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />}
          variant="text"
          onClick={() => navigate(PATH_DASHBOARD.general.app)}
        >
          {t('adminbanner.backToDashboard')}
        </Button>
      </Stack>
      <Stack sx={{ p: 6 }}>
        <UnverifiedReviewsList />
        <Card sx={{ p: 4 }}>
          <Stack sx={{ pb: 2 }}>
            <TextField
              label="Search id, name or e-mail"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Stack>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Last seen</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user) => {
                  const isOnline = userPresenceMap[user.id]?.state === 'online';

                  const lastSeen = isOnline
                    ? 'Online now'
                    : userPresenceMap[user.id]
                    ? formatDistanceToNow(new Date(userPresenceMap[user.id]?.offline_at as number))
                    : 'Not registered';
                  return (
                    <TableRow
                      key={user.id}
                      sx={{
                        borderBottom: `1px solid ${theme.palette.grey[200]}`,
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Stack spacing={1} direction="row" alignItems="center">
                          <Tooltip title={isOnline ? 'Online now' : 'Offline'}>
                            <Box
                              sx={{
                                height: 8,
                                width: 8,
                                borderRadius: '50%',
                                bgcolor: isOnline ? 'primary.light' : 'error.main',
                              }}
                            />
                          </Tooltip>
                          <Tooltip title={user.id}>
                            <Typography variant="body2">{user.displayName}</Typography>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {lastSeen}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        <CountUnverifiedReviews userId={user.id} />
                      </TableCell> */}
                      <TableCell align="right">
                        {/* ic:round-keyboard-arrow-right */}
                        <Tooltip title={`Open drawer ${user.displayName}`}>
                          <IconButton onClick={() => setOpenDrawer(user.id)}>
                            <Iconify icon="ic:round-keyboard-arrow-right" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>
      <UserDrawer
        user={users.find((u) => u.id === openDrawer)}
        userId={openDrawer}
        onClose={handleCloseDrawer}
      />
    </Page>
  );
}
