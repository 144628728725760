import {
  Box,
  Card,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  PaperProps,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Transition } from 'src/sections/@dashboard/user/initial/InitialSettings';
import { Button } from '@mui/material';
import Iconify from 'src/components/Iconify';
import useUnverifiedReviews from './useUnverifiedReviews';
import { Stack } from '@mui/material';
import { Review } from 'src/redux/slices/reviewSlice';
import DisplayVideo from '../reviews/DisplayVideo';
import { Collection, ReviewType } from 'src/enum';
import HoverCard from 'src/components/simply/Cards/HoverCard';
import { ReviewerDetails } from 'src/sections/@dashboard/user/profile/ProfilePostCard';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { DB } from 'src/contexts/FirebaseContext';
import { collection, doc, getDoc, getDocs, limit, query, setDoc, where } from 'firebase/firestore';
import { getCollection } from 'src/api';
import { PublicConfig } from 'src/redux/slices/publicConfig';
import Draggable from 'react-draggable';
import useRecentDistanceReviews from './useRecentDistanceReviews';
import { format, formatDistance } from 'date-fns';

export default function UnverifiedReviewsList() {
  const { list } = useUnverifiedReviews();
  const [modal, setModal] = useState(false);
  const handleCloseModal = () => setModal(false);
  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setModal(true);
        }}
        color="warning"
        // endIcon={<Iconify icon="tabler:external-link" />}
      >
        {list.length} unverfied review(s)
      </Button>
      <Dialog
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={handleCloseModal}
        aria-describedby="handle-reviews-list"
        maxWidth="lg"
      >
        <HandleReviews reviews={list} handleClose={handleCloseModal} />
      </Dialog>
    </>
  );
}

export function HandleReviews({
  reviews,
  handleClose,
}: {
  reviews: (Review & { workspaceId: string; createTime: { toDate: () => Date } })[];
  handleClose: () => void;
}) {
  const [modal, setModal] = useState<
    (Review & { workspaceId: string; createTime: { toDate: () => Date } }) | null
  >(null);
  const handleCloseModal = () => setModal(null);
  return (
    <>
      <DialogTitle id="alert-dialog-title">{'Unverified reviews'}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }} />
        <Stack sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            {reviews.map((r) => (
              <Grid xs={3}>
                {r.type === ReviewType.VIDEO ? (
                  <Card sx={{ p: 1 }} onClick={() => setModal(r)}>
                    <Box sx={{ overflow: 'hidden', objectFit: 'cover' }}>
                      <DisplayVideo
                        rating={r?.rating}
                        videoProcessed={r?.videoProcessed}
                        videoPlaybackId={r?.videoPlaybackId}
                        thumbFrame={r?.thumbFrame}
                        reviewerName={r?.reviewer?.name}
                      />
                    </Box>
                    <ReviewActions
                      workspaceId={r.workspaceId}
                      reviewId={r.id}
                      inviteId={r?.inviteId}
                    />
                  </Card>
                ) : (
                  <HoverCard sx={{ p: 2, pb: 1, height: '100%' }} onClick={() => setModal(r)}>
                    <Stack justifyContent="space-between">
                      <Stack>
                        {r.createTime && (
                          <Typography variant="subtitle2">
                            {format(r.createTime.toDate(), 'dd-MM-yyyy')}
                          </Typography>
                        )}
                        {r.title && <Typography variant="h5">{r.title}</Typography>}
                        {r.description && <Typography>{r.description}</Typography>}
                      </Stack>
                      <ReviewerDetails name={r?.reviewer?.name as string} rating={r.rating} />
                    </Stack>
                    <ReviewActions
                      workspaceId={r.workspaceId}
                      reviewId={r.id}
                      inviteId={r?.inviteId}
                    />
                  </HoverCard>
                )}
              </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
      <Dialog
        open={Boolean(modal)}
        TransitionComponent={Transition}
        keepMounted
        PaperComponent={PaperComponent}
        fullWidth
        onClose={handleCloseModal}
        aria-describedby="compare-reviews-list"
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Reviews within +4 and -4 days of the review
        </DialogTitle>
        <DialogContent>
          {modal && (
            <CompareRecentReviews
              workspaceId={modal.workspaceId}
              createTime={modal.createTime.toDate() as Date}
              reviewId={modal.id}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function CompareRecentReviews({
  workspaceId,
  createTime,
  reviewId,
}: {
  workspaceId: string;
  createTime: Date;
  reviewId: string;
}) {
  const compareReviews = useRecentDistanceReviews({ workspaceId, createTime });
  return (
    <Stack sx={{ width: '100%' }}>
      <Grid container spacing={0}>
        {compareReviews.list
          .filter((r) => r.id !== reviewId)
          .map((r) => (
            <Grid xs={6}>
              {r.type === ReviewType.VIDEO ? (
                <Card sx={{ p: 1 }}>
                  <Box sx={{ overflow: 'hidden', objectFit: 'cover' }}>
                    <DisplayVideo
                      rating={r?.rating}
                      videoProcessed={r?.videoProcessed}
                      videoPlaybackId={r?.videoPlaybackId}
                      thumbFrame={r?.thumbFrame}
                      reviewerName={r?.reviewer?.name}
                    />
                  </Box>
                </Card>
              ) : (
                <HoverCard sx={{ p: 2, pb: 1, height: '100%' }}>
                  <Stack justifyContent="space-between">
                    <Stack>
                      {r.createTime && (
                        <Typography variant="subtitle2">
                          {format(r.createTime, 'dd-MM-yyyy')}
                        </Typography>
                      )}
                      {r.title && <Typography variant="h5">{r.title}</Typography>}
                      {r.description && <Typography>{r.description}</Typography>}
                    </Stack>
                    <ReviewerDetails name={r?.reviewer?.name as string} rating={r.rating} />
                  </Stack>
                </HoverCard>
              )}
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
}

function ReviewActions({
  workspaceId,
  reviewId,
  inviteId,
}: {
  workspaceId: string;
  reviewId: string;
  inviteId: string | null;
}) {
  const [loading, setloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <CardActions sx={{ justifyContent: 'space-between' }}>
      <LoadingButton
        onClick={async () => {
          try {
            if (!workspaceId) return alert('Something is not working!');
            setloading(true);

            const productRef = doc(
              DB,
              getCollection[Collection.USERS_REVIEWS](workspaceId),
              reviewId
            );

            await setDoc(productRef, { verified: false }, { merge: true });

            enqueueSnackbar('Review rejected', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });

            console.error(error);
          } finally {
            setloading(false);
          }
        }}
        variant="contained"
        color="error"
        size="small"
      >
        {'Afvis'}
      </LoadingButton>
      <Button
        onClick={async () => {
          try {
            const q = query(
              collection(DB, getCollection[Collection.PUBLIC]()),
              where('userId', '==', workspaceId),
              limit(1)
            );
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) return alert('Something is not working!');
            const config = docsSnap.docs[0]?.data() as PublicConfig;
            if (inviteId) {
              return window.open(
                `https://app.simplyreview.com/r/${config.slug}/${inviteId}?r=${reviewId}`,
                '_blank'
              );
            }
            return window.open(
              `https://app.simplyreview.com/r/${config.slug}?r=${reviewId}`,
              '_blank'
            );
          } catch (error) {
            console.error(error);
            alert(`Something is not working! - ${JSON.stringify(error)}`);
          }
        }}
        variant="contained"
        size="small"
      >
        {'Godkend review'}
      </Button>
    </CardActions>
  );
}
