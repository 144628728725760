import { useMediaQuery } from 'react-responsive';

export default function useResponsiveQuery() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  return { isDesktop, isMobile, isTablet, isNotMobile };
}
