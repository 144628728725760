import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';
import { useEffect } from 'react';

const storage = getStorage();

// const metadata = {
//   contentType: 'image/jpeg',
// };

export const upload = async (
  file: File | Blob,
  path: string,
  metadata: {}
): Promise<{ url: string } | { error: Error }> => {
  const storageRef = ref(storage, path + ((file as File)?.name || 'reviewer_video'));
  try {
    const snapshot = await uploadBytes(storageRef, file, { customMetadata: metadata });
    const downloadUrl = await getDownloadURL(snapshot.ref);

    return { url: downloadUrl };
  } catch (error) {
    console.error(error);
    return { error };
  }
};
export const uploadResumable = (file: File | Blob, path: string, metadata: {}) => {
  const storageRef = ref(storage, path + ((file as File)?.name || 'reviewer_video'));

  return uploadBytesResumable(storageRef, file, { customMetadata: metadata });
};

export default function useUpload({ file, path, metadata }: any) {
  // Upload file and metadata to the object 'images/mountains.jpg'

  useEffect(() => {
    upload(file, path, metadata);

    return () => {};
  }, [file]);

  // Listen for state changes, errors, and completion of the upload.
}
