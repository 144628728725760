import { Grid, Card, useTheme, ToggleButtonGroup, Slide, Typography } from '@mui/material';
import BookingPageSettings from './BookingPageSettings';
import Label from '../../../components/Label';
import { RequestState, IMAGE_METADATA_TYPE } from '../../../enum';
import BookingInfo from './BookingIntro';
import { httpsCallable } from 'firebase/functions';
import { DB, Functions } from '../../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { upload } from '../../../hooks/useUpload';
import AlertDialogSlide from './ConfirmDialog';
import { useTranslation } from 'react-i18next';
import useBookingSchedulePageSettings from './useBookingSchedulePageSettings';
import Box from '@mui/material/Box';
import { hex2rgb } from '../../../utils/colors';
import { useState } from 'react';
import AvailbilityCalendar from '../AvailbilityCalendar';
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';

function getBackgroundColor(color?: string) {
  if (color) {
    const [r, g, b] = hex2rgb(color);
    return `rgba(${r},${g},${b}, 0.1)`;
  }
  return null;
}

// const defaultDescription = `Thanks for sharing your experience with us 🏆
// We would love a video review. Keep the review short, precise and honest.

// Thanks a million 🤩`;

interface Props {
  userId: string;
}

export default function PageSettings({ userId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useTranslation();
  const pageSettings = useBookingSchedulePageSettings();

  const [tab, setTab] = useState<'PAGE' | 'AVAILABILITY'>('PAGE');

  // const defaultTitle = `Share your experience with us`;
  const backgroundColor = getBackgroundColor(pageSettings?.color);
  const onSubmitSlug = async (data: any) => {
    try {
      if (!userId) throw new Error(`userId invalid`);

      const updateMessage = t(`reviewPageSettings.update.message.general`);
      const updateMessageVideo = t('reviewPageSettings.update.message.video');
      // enqueueSnackbar(
      //   data.videoIntro !== pageSettings.videoIntroInit ? updateMessageVideo : updateMessage,
      //   {
      //     variant: 'info',
      //   }
      // );
      const configId = pageSettings.id;
      let logoUrl;
      if (!userId || !configId) {
        throw new Error('No id returned');
      }
      if (data.logo !== pageSettings.logoInit) {
        const response = await upload(
          data.logo as Blob,
          `workspaceBookings/${userId}/schedules/${configId}/`,
          {
            userId,
            configId,
            type: IMAGE_METADATA_TYPE.BOOKING_SETTINGS,
          }
        );

        // @ts-ignore
        if (response?.url) {
          // @ts-ignore
          logoUrl = response.url;
        }
      }
      const userRef = doc(collection(DB, `workspaceBookings/${userId}/schedules`), configId);
      await updateDoc(userRef, {
        title: data.title,
        description: data.description,
        color: pageSettings.color,
        duration: Number(data.duration),
        rollingdays: Number(data.rollingdays),
        language: data.pageLanguage,
        logo: logoUrl || data.logo,
        weekAvailability: pageSettings.weekAvailability,
        timeZone: 'Europe/Copenhagen',
      });
      // const functionRef = httpsCallable(Functions, 'setInvitePageSettings');
      // const response: any = await functionRef({
      //   id: pageSettings.id,
      //   title: pageSettings.title,
      //   description: pageSettings.description,
      //   questions: pageSettings.questions,
      //   color: pageSettings.color,
      //   onlyVideo: pageSettings.onlyVideo,
      //   reviewerPageLanguage: pageSettings.reviewerPageLanguage,
      //   companyLogo: logoUrl,
      // });
      // if (data.videoIntro !== pageSettings.videoIntroInit && data.videoIntro) {
      //   await upload(data.videoIntro as Blob, `workspaceReviews/${userId}/config/${configId}/`, {
      //     userId,
      //     configId,
      //   });
      // }
      // console.log({ response: response.data });
      enqueueSnackbar(t('reviewPageSettings.update.message.success'));
      // pageSettings.setVideoIntroPreview(undefined);
      // onClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: 'PAGE' | 'AVAILABILITY'
  ) => {
    if (!['PAGE', 'AVAILABILITY'].includes(newAlignment)) return;
    setTab(newAlignment);
  };
  return (
    <Grid container spacing={2} sx={{ pl: 4, height: '100%', mt: '-72px', pt: '72px' }}>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ overflowY: 'scroll', height: '100%', maxHeight: '100vh', pb: 4, pr: 4 }}
      >
        {/* {pageSettings.state === RequestState.RESOLVED && ( */}
        <BookingPageSettings
          key={pageSettings.id}
          configId={pageSettings.id}
          userId={userId}
          onSubmitSlug={onSubmitSlug}
          setavatar={pageSettings.setavatar}
          // setVideoIntro={pageSettings.setVideoIntroPreview}
          onTitleChange={pageSettings.setTitle}
          onDescriptionChange={pageSettings.setDescription}
          color={pageSettings.color}
          onColorChange={pageSettings.setColor}
          onChangeDuration={pageSettings.setDuration}
          onPageLanguageChange={pageSettings.setPageLanguage}
          onRollingDaysChange={pageSettings.setRollingdays}
          // onlyVideo={pageSettings.onlyVideo}
          // onOnlyVideoChange={pageSettings.setOnlyVideo}
          logoInit={pageSettings.logoInit}
          // videoIntroInit={pageSettings.videoIntroInit}
          tab={tab}
          onChangeTab={handleChange}
          defaultValues={{
            title: pageSettings.titleInit,
            description: pageSettings.descriptionInit,
            logo: pageSettings.logoInit,
            duration: pageSettings.durationInit,
            rollingdays: pageSettings.rollingdaysInit,
            // videoIntro: pageSettings.videoIntroInit,
            pageLanguage: pageSettings.pageLanguage,
          }}
        />
        {/* )} */}
      </Grid>

      <Grid
        xs={12}
        md={8}
        sx={{
          height: '100%',
          // position: 'relative',
          // display: 'flex',
          // overflowY: 'scroll',
          // justifyContent: 'center',
          // maxHeight: '100vh',
          // height: '100%',
          alignItems: 'center',
          // pr: 2,
          // pt: 2,
          // pb: 2,
          pt: 0,
          paddingTop: 0,
          background: backgroundColor || theme.palette.background.neutral,
        }}
      >
        <Slide key="TAB1" direction="down" in={tab === 'PAGE'} mountOnEnter unmountOnExit>
          <Box
            sx={{
              maxHeight: '100vh',
              overflowY: 'scroll',
              p: 6,
              position: 'relative',
            }}
          >
            <Label
              variant={'ghost'}
              color={'success'}
              sx={{ position: 'absolute', zIndex: 100, left: 96, top: 36 }}
            >
              {t('booking.preview.badge')}
            </Label>
            <Card
              sx={{
                mr: 6,
                ml: 6,
                mt: 0,
                p: 3,
                borderBottom: `4px solid ${pageSettings.color}`,
                height: '100%' /* height: '100%', overflowY: 'scroll' */,
              }}
            >
              {/* {ReviewType.INTRO === reviewType && ( */}

              <Box>
                <BookingInfo
                  key={pageSettings.id}
                  configId={pageSettings.id}
                  workspaceId={userId}
                  weekAvailability={pageSettings.weekAvailability}
                  avatar={pageSettings.avatar}
                  title={pageSettings.title}
                  color={pageSettings.color}
                  duration={pageSettings.duration}
                  rollingdays={pageSettings.rollingdays}
                  description={pageSettings.description}
                  // questions={pageSettings.questions}
                  // onlyVideo={pageSettings.onlyVideo}
                  pageLanguage={pageSettings.pageLanguage}
                  // reviewType={reviewType}
                  // videoIntroPreview={pageSettings.videoIntroPreview}
                  // videoIntro={pageSettings.videoIntroInit}
                  // setReviewType={() => pageSettings.setShowModal(true)}
                />
              </Box>
            </Card>
          </Box>
        </Slide>
        <Slide key="TAB2" direction="up" in={tab === 'AVAILABILITY'} mountOnEnter unmountOnExit>
          <Box
            sx={{
              height: 'calc(100%)',
              overflowY: 'scroll',
              p: 6,
              position: 'relative',
            }}
          >
            <Card
              sx={{
                mr: 6,
                ml: 6,
                mt: 0,
                p: 2,
                height: '100%' /* height: '100%', overflowY: 'scroll' */,
                position: 'relative',
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Don't worry, we will have our AI look at the calendar you connected and make sure
                you are not double booked.
              </Typography>
              <AvailbilityCalendar
                customColor={pageSettings.color}
                weekAvailability={pageSettings.weekAvailability}
                setWeekAvailability={pageSettings.setWeekAvailability}
              />
            </Card>
          </Box>
        </Slide>
      </Grid>
      <AlertDialogSlide
        title={t('reviewPageSettings.preview.alert')}
        open={pageSettings.showModal}
        handleClose={() => pageSettings.setShowModal(false)}
      />
    </Grid>
  );
}
