import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import {
  Stack,
  Typography,
  Rating,
  Box,
  CircularProgress,
  Tooltip,
  Link,
  Alert,
} from '@mui/material';
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { NewReviewFormValues } from '../../@types/blog';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from '../../contexts/FirebaseContext';
import { getCollection } from '../../api';
import { Collection, ReviewType } from '../../enum';
// import useLocales from '../../hooks/useLocales';
import { RHFCheckbox } from '../../components/hook-form/RHFCheckbox';
import Iconify from '../../components/Iconify';
import BrandButton from '../../components/buttons/BrandButton';
// import InfoTooltip from '../../components/InfoTooltip';
// import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
// import { PhoneCountryField, PhoneField, countries, phoneCustom } from '../../components/PhoneField';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'change-case';
import { Terms } from '../../config';
import useResponsiveQuery from '../../hooks/useResponsiveQuery';
import { TmpReviewDataContext } from './TmpReviewDataProvider';
import { useContext } from 'react';

// ----------------------------------------------------------------------

export const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function ReviewFormText({
  userId,
  setIsSuccess,
  brandColor,
  goBack,
  avatar,
  onVerify,
  reviewerPageLanguage,
}: any) {
  const { t } = useTranslation();
  const { tmpStorage, setTmpStorage, resetTmpStorage } = useContext(TmpReviewDataContext);
  const [, setSearchParams] = useSearchParams();
  const { inviteId } = useParams<{ inviteId?: string }>();

  // const [rating, setrating] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { isDesktop } = useResponsiveQuery();
  const NewReviewSchema = Yup.object().shape({
    title: Yup.string().required(t('validation.title', { lng: reviewerPageLanguage }) as string),
    description: Yup.string().required(
      t('validation.description', { lng: reviewerPageLanguage }) as string
    ),
    name: Yup.string().required(t('validation.name', { lng: reviewerPageLanguage }) as string),
    // email: Yup.string().email('Email er ugyldig').required('Email er påkrævet'),
    // phone: Yup.string()
    //   .transform((currentValue) => currentValue.split(' ').join(''))
    //   .matches(
    //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //     t('validation.phone')
    //   ),
    rating: Yup.number().required(t('validation.rating', { lng: reviewerPageLanguage }) as string),
    consent: Yup.boolean().required(t('validation.terms', { lng: reviewerPageLanguage }) as string),
    // content: Yup.string().min(1000).required('Content is required'),
    // file: Yup.mixed().required('Image is required'),
    // facebook: Yup.mixed().required('Facebook er påkrævet'),
  });

  const defaultValues = {
    title: tmpStorage.title || '',
    description: tmpStorage.description || '',
    rating: tmpStorage.rating || null,
    name: tmpStorage.name || '',
    consent: false,
    verified: null,
  };

  const methods = useForm<NewReviewFormValues>({
    resolver: yupResolver(NewReviewSchema),
    defaultValues,
  });

  const {
    // reset,
    watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const onSubmit = async (data: NewReviewFormValues) => {
    try {
      if (!userId) return alert(t('validation.link', { lng: reviewerPageLanguage }));
      await new Promise((resolve) => setTimeout(resolve, 500));
      // console.log({ data: { ...data, rating } });

      const docRef = doc(collection(DB, getCollection[Collection.USERS_REVIEWS](userId)));
      // const ip = await axios.get('https://api.ipify.org/?format=json');
      // const phoneNumber = `${data.countryCode}${data.phone}`;
      await setDoc(docRef, {
        createTime: new Date(),
        title: data.title,
        description: data.description,
        rating: data.rating,
        reviewer: {
          // id: data.facebook.id,
          name: data.name || null,
          // phone: phoneNumber || null,
          // email: data.email || null,
        },
        // ip: ip.data.ip,
        verified: null,
        consent: new Date(),
        type: ReviewType.TEXT,
        inviteId: inviteId || null,
      });
      // setIsSuccess(docRef.id);
      onVerify(docRef.id);
      setSearchParams({ r: docRef.id });

      enqueueSnackbar('Thanks a lot!');
      resetTmpStorage();
    } catch (error) {
      console.error(error);
    }
  };

  // const onFBVerify = (data: any) => {
  //   console.log({ data });

  //   setValue('facebook', data);
  // };

  // const onPhoneChange = (data: any) => {
  //   console.log({ data });

  //   setValue('phone', data);
  // };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {/* <Card sx={{ p: 3 }}> */}
      <Stack spacing={3}>
        <Box>
          <BrandButton
            startIcon={<Iconify icon={'eva:arrow-back-outline'} />}
            customColor={brandColor}
            onClick={() => {
              setTmpStorage({
                rating: values.rating,
                title: values.title,
                description: values.description,
                name: values.name,
              });
              goBack();
            }}
          >
            {sentenceCase(t('general.back', { lng: reviewerPageLanguage }))}
          </BrandButton>
        </Box>
        <div>
          <LabelStyle>{t('review.form.ratingLabel', { lng: reviewerPageLanguage })}</LabelStyle>
          <Rating
            value={values.rating}
            size="large"
            precision={0.5}
            onChange={(event, newValue) => {
              setValue('rating', newValue);
            }}
          />
          {errors.rating && (
            <Alert severity="error">{t('validation.rating', { lng: reviewerPageLanguage })}</Alert>
          )}
        </div>

        <div>
          <LabelStyle>
            {t('review.form.descriptionLabel', { lng: reviewerPageLanguage })}*
          </LabelStyle>
          <RHFTextField
            name="description"
            label={t('review.form.descriptionPlaceholder', { lng: reviewerPageLanguage })}
            multiline
            rows={3}
            customColor={brandColor}
          />
        </div>
        <div>
          <LabelStyle>{t('review.form.titleLabel', { lng: reviewerPageLanguage })}*</LabelStyle>
          <RHFTextField
            name="title"
            label={t('review.form.titlePlaceholder', { lng: reviewerPageLanguage })}
            customColor={brandColor}
          />
        </div>
        <div>
          <LabelStyle>{t('review.form.nameLabel', { lng: reviewerPageLanguage })}*</LabelStyle>
          <RHFTextField
            name="name"
            label={t('review.form.namePlaceholder', { lng: reviewerPageLanguage })}
            customColor={brandColor}
          />
        </div>
        {/* <div>
            <LabelStyle>{t('review.form.emailLabel')}*</LabelStyle>
            <RHFTextField
              name="email"
              type="email"
              label={t('review.form.emailPlaceholder')}
              customColor={brandColor}
            />
          </div> */}
        {/* <div>
            <Stack>
              <Stack justifyContent="space-between" alignItems="center" direction="row" sx={{}}>
                <LabelStyle>{t('review.form.phoneLabel')}</LabelStyle>
                <Stack alignItems="center" direction="row">
                  <LabelStyle sx={{ margin: 0 }} variant="subtitle2">
                    {t('review.form.whyPhoneLabel')}
                  </LabelStyle>
                  <InfoTooltip title={t('review.form.whyPhoneExplainer')} />
                </Stack>
              </Stack>
              <Stack flexDirection={'row'}>
                <PhoneField
                  // {...register(`invites.${i}.phone`)}
                  name={'phone'}
                  placeholder="11 22 33 44"
                  label={t('review.form.phonePlaceholder')}
                  InputProps={{
                    inputComponent: phoneCustom as any,
                    startAdornment: (
                      <InputAdornment sx={{ width: 200 }} position="start">
                        <PhoneCountryField id="outlined-select-currency" name={'countryCode'}>
                          {countries.map((option) => (
                            <option key={option.phone} value={option.phone}>
                              {option.flag} {option.phone}
                            </option>
                          ))}
                        </PhoneCountryField>
                      </InputAdornment>
                    ),
                  }}
                  error={errors.phone ? true : false}
                  helperText={errors?.phone?.message || ''}
                />
              </Stack>
            </Stack>
          </div> */}
        <Stack direction="row" alignItems="center" sx={{ mt: 3 }} justifyContent="space-between">
          <RHFCheckbox
            name="consent"
            customColor={brandColor}
            label={t('review.form.terms', { lng: reviewerPageLanguage }) as string}
          />
        </Stack>
        <Typography
          variant="body2"
          style={{ marginTop: 0 }}
          sx={{
            textAlign: isDesktop ? 'right' : 'right',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          <Link target="_blank" sx={{ mt: 0 }} color="grey.600" href={Terms.TERMS_AND_CONDITIONS}>
            {t('terms.read', { lng: reviewerPageLanguage }) as string}
          </Link>
        </Typography>
        <BrandButton
          fullWidth
          disabled={!values.consent || isSubmitting}
          customColor={brandColor}
          type="submit"
          variant="contained"
          size="large"
        >
          {isSubmitting ? <CircularProgress /> : t('review.form.button')}
        </BrandButton>
      </Stack>
    </FormProvider>
  );
}
